*, *:before, *:after {
	box-sizing: border-box;
	outline: none !important;
}

* {
    &::selection {
        background: $blue;
		color: #fff;
    }
	@media all and (min-width: 1201px) {
		// @include scrollbars(7px, #dbdbdb, $blue);
	}
}

html {
	width: 100%;
	max-width: 100%;
	min-height: 100%;
}
body {
	overflow-x: hidden;
	position: relative;
	min-width: 320px;
	margin: 0;
	background: $bg;
	color: $text;
	font: 400 16px / 1.5 $font;
	@media all and (min-width: 1201px) {
		&.compensate-for-scrollbar {
			.header {
				right: 7px;
			}
		}
	}
}


// Text element----------------------------
ul, ol {
	margin: 0;
	padding: 0;
	list-style: none;
}
a {
	transition: .3s all $trans;
	text-decoration: none;
	color: inherit;
}
h1, h2, h3, h4, h5, h6 {
	margin: 0;
}
p {
	margin: 0;
}


// FORM ELEMENTS---------------------------
textarea, input {
	font-family: inherit;
	box-shadow: none;
	border-radius: 0;
}
textarea {
	resize: none;
}
button {
	margin: 0;
	padding: 0;
	border: 0;
	cursor: pointer;
	user-select: none;
}


//IMM, SVG----------------------------------
img, svg {
	max-width: 100%;
	display: block;
	height: auto;
	width: auto;
}
svg {
	flex-shrink: 0;
}
[data-toggle],
[data-target] {
	user-select: none;
}

//j-wow----------------------------
.j-wow {
	visibility: hidden;
	&.animated {
		visibility: visible;
	}
}

.wrapper {
	overflow: hidden;
}

.fit-cover {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
}

.fit-contain {
    object-fit: contain;
    object-position: center;
    width: 100%;
    height: 100%;
}

.container {
	max-width: 1212px;
	width: 100%;
	padding: 0 36px;
	margin: 0 auto;
}
.row {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -12px;
}
.col {
	padding: 0 12px;
}


.social {
	&__list {
		display: flex;
		margin: 0 -5px;
	}
	&__li {
		padding: 0 5px;
	}
	&__a {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 30px;
		height: 30px;
		fill: $blue;
		&:hover {
			background: $blue;
			fill: #fff;
		}
		&--instagram {
			svg {
				width: 14px;
				height: 14px;
			}
		}
		&--facebook {
			svg {
				width: 7px;
				height: 14px;
			}
		}
		&--twitter {
			svg {
				width: 20px;
				height: 16px;
			}
		}
		&--linkedin {
			svg {
				width: 14px;
				height: 14ox;
			}
		}
	}
	&--green {
		.social {
			&__a {
				fill: $green;
				&:hover {
					background: $green;
					fill: $text;
				}
			}
		}
	}
}

.line {
	position: absolute;
	fill: none;
	stroke: $blue;
	stroke-width: 3px;
	stroke-linecap: round;
	max-width: unset;
	z-index: -1;
}

.title-sec {
	font: 900 46px / 1 $font;
}

.desc-sec {
	max-width: 752px;
}


@media screen and (max-width: 992px) {
	body {
		font-size: 14px;
	}

	.title-sec {
		font-size: 32px;
	}
}

@media screen and (max-width: 767px) {
	.container {
		padding: 0 24px;
	}

	.title-sec {
		text-align: center;
		font-size: 24px;
	}
}