.btn {
    height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: $blue;
    color: #fff;
    font: 700 14px / 1 $font;
    border-radius: 10px;
    padding: 0 30px;
    text-transform: uppercase;
    transition: .3s $trans;
    &:hover {
        background: $blueH;
    }
    &--green {
        background: $green;
        color: $text;
        &:hover {
            background: $greenH;
        }
    }
}


@media screen and (max-width: 992px) {
	.btn {
		height: 40px;
	}
}