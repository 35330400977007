.percussion {
  font: 400 14px / 1 $font;
  margin-top: 68px;
  height: 649px;
  padding: 38px 0 96px 0;
  background: linear-gradient(126deg, #020202 17.28%, rgba(2, 2, 2, 0) 67.8%, #020202 100%), url("/assets/landing/img/percussion_page/hero.png");
  background-size: cover;
  background-position: 72%;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .percussion__title {
    position: relative;
    z-index: 2;
    color: $green;
    text-align: center;
    font-size: 11px;
    font-weight: 700;
    line-height: 218%;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 12px;

    @media (min-width: $mobile) {
      font-size: 12px;
      line-height: 200%;
      text-align: left;
      margin-bottom: 11px;
    }
  }

  .percussion__square {
    display: none;
    position: absolute;
    width: 48px;
    height: 48px;
    border-radius: 12px;
    z-index: 3;
    animation: 1.5s kv infinite linear, 3s background-kv infinite alternate linear;
    &--1 {
      left: 5%;
      top: 17%;
    }
    &--2 {
      width: 35px;
      height: 35px;
      top: 10%;
      right: 12%;
    }
    &--3 {
      bottom: 30%;
      right: 20%;
    }

    @media (min-width: 768px) {
      display: block;
    }
  }

}