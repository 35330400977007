.info-text {
  margin-top: #{"min(10vw, 48px)"};
  margin-bottom: #{"min(25vw, 120px)"};

  .info-text__wrapper {
    border-radius: 20px;
    background: #fff;
    box-shadow: 0 4px 18px 0 rgba(66, 75, 90, 0.15);
    padding: 15px 20px;
    position: relative;

    @media (min-width: $mobile) {
      padding: 39px 35px;
    }

    .info-text__read-more {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      padding-bottom: 12px;

      border-radius: 20px;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 180px;
      z-index: 2;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 1) 80%);

      .info-text__show-more {
        font-size: 16px;
        line-height: 1.5;
        color: $blue;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        text-decoration: underline;

        &:hover {
          color: $blueH;
        }


      }
    }

    .info-text__box {
      font-size: 16px;
      line-height: 1.5;
      max-height: 200px;
      overflow: hidden;

      &.info-text__box--open {
        max-height: 100%;
      }

      h2 {
        display: block;
        font-size: 1.5em;
        margin: 0.83em 0;
        font-weight: bold;
        text-align: center;
      }

      h3 {
        display: block;
        font-size: 1.17em;
        margin: 1em 0;
        font-weight: bold;
      }

      p {
        display: block;
        margin: 1em 0;
      }

      ol {
        display: block;
        list-style-type: decimal;
        margin: 1em 0;
        padding-left: 40px;
      }

      ul {
        display: block;
        list-style-type: disc;
        margin: 1em 0 1em;
        padding-left: 40px;
      }

      li {
        display: list-item;
      }

    }
  }
}
