.banner {
    min-height: 100vh;
    display: flex;
    align-items: center;
    padding: 76px 0 40px;
    &__wrapper {
        display: flex;
    }
    &__text {
        width: 50%;
        position: relative;
        margin: 15px 0 0;
        &-inner {
            position: relative;
        }
    }
    &__subtitle {
        font: 700 12px / 1.5 $font;
        color: $blue;
        text-transform: uppercase;
        letter-spacing: 1px;
    }
    &__title {
        font: 900 66px / 1.2 $font;
        color: $text2;
        margin: 20px 0 0;
    }
    &__desc {
        color: $text2;
        margin: 18px 0 0;
        max-width: 422px;
    }
    &__form {
        max-width: 458px;
        margin: 42px 0 0 -5px;
    }
    &__imgs {
        width: 50%;
        position: relative;
    }
    &__kv {
        position: absolute;
        width: 48px;
        height: 48px;
        border-radius: 12px;
        z-index: 1;
        animation: 1.5s kv infinite linear, 3s background-kv infinite alternate linear;
        &--1 {
            left: -155px;
            top: 22px;
        }
        &--2 {
            top: 220px;
            left: -65px;
        }
        &--3 {
            width: 35px;
            height: 35px;
            top: -50px;
            right: 35px;
        }
    }
    &__line {
        width: 522px;
        height: 327px;
        transform: rotate(-93deg);
        left: -405px;
        top: 100px;
        stroke-width: 4.5px;
    }
    &__circle {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: $green;
        position: absolute;
        top: 400px;
        left: 9px;
    }
}

@keyframes kv {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes background-kv {
    0% {
        background-color: $greenH;
        opacity: 0.8;
    }
    30% {
        background-color: $green;
        opacity: 1;
    }
    100% {
        background-color: $green;
        opacity: 1;
    }
}

.banner-imgs {
    position: relative;
    margin: 0 -9px;
    height: 433px;
    &__line {
        width: 849px;
        height: 532px;
        top: -54px;
        left: -48px;
    }
    &__bl {
        width: 50%;
        position: absolute;
        padding: 0 9px;
        &--1 {
            left: 0;
            top: 0;
            height: 146px;
        }
        &--2 {
            right: 0;
            top: 0;
            height: 263px;
        }
        &--3 {
            left: 0;
            bottom: 0;
            height: 263px;
        }
        &--4 {
            right: 0;
            bottom: 0;
            height: 146px;
        }
    }
    &__picture {
        display: block;
        border-radius: 60px;
        height: 100%;
        overflow: hidden;
        img {
            @extend .fit-cover;
        }
    }
    &__address {
        display: block;
        background: $green;
        border-radius: 60px;
        position: relative;
        z-index: 1;
        text-align: center;
        height: 100%;
        padding: 60px 0 0;
        animation: 1s banner_address ease infinite;
        &:hover {
            transform: scale(1.1);
        }
        &-svg {
            width: 68px;
            height: 98px;
            z-index: -1;
            fill: $greenH;
            position: absolute;
            top: 14px;
            left: 50%;
            transform: translateX(-50%);
            animation: 1s banner_address_svg ease infinite;
        }
        &-how {
            font: 400 12px / 1 $font;
            text-transform: uppercase;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #424B5A;
            fill: $blue;
            svg {
                width: 20px;
                height: 20px;
                margin: 0 0 0 10px;
            }
        }
        &-title {
            color: $blue;
            font: 700 36px / 1.5 $font;
        }
    }
}

@keyframes banner_address {
    0% {
        background: $green;
    }
    50% {
        background: $greenH;
    }
    100% {
        background: $green;
    }
}

@keyframes banner_address_svg {
    0% {
        fill: $greenH;
    }
    50% {
        fill: $green;
    }
    100% {
        fill: $greenH;
    }
}


@media screen and (max-width: 1200px) {
    .banner {
        &__text {
            margin: 0;
        }
        &__kv {
            &--2 {
                left: -50px;
            }
            &--3 {
                display: none;
            }
        }
    }

    .banner-imgs {
        &__line {
            top: 0;
        }
    }
}


@media screen and (max-width: 992px) {
    .banner {
        &__title {
            font-size: 46px;
            max-width: 324px;
        }
        &__desc {
            max-width: 309px;
        }
        &__form {
            margin: 30px 0 0;
        }
        &__line,
        &__circle {
            display: none;
        }
        &__kv {
            &--2 {
                top: 400px;
                left: 0;
            }
            &--1 {
                display: none;
            }
        }
        &__form {
            max-width: 324px;
        }
    }

    .banner-imgs {
        height: 342px;
        margin: 0 -8px;
        &__line {
            width: 785px;
            height: 492px;
            stroke-width: 3.2px;
            left: -40px;
            top: 0;
            stroke-width: 2.8;
        }
        &__bl {
            padding: 0 8px;
            &--1 {
                height: 116px;
                width: 60%;
            }
            &--2 {
                height: 100px;
                top: unset;
                bottom: 0;
                width: 40%;
            }
            &--3 {
                height: 208px;
                width: 60%;
            }
            &--4 {
                bottom: unset;
                top: 0;
                height: 227px;
                width: 40%;
            }
        }
        &__picture {
            border-radius: 32px;
        }
        &__address {
            padding: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 32px;
            &-svg {
                position: relative;
                width: 68px;
                height: 98px;
                left: unset;
                transform: none;
            }
            &-how {
                display: none;
            }
            &-title {
                font-size: 16px;
                margin: 20px 0 0;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .banner {
        padding: 90px 0 0;
        min-height: unset;
        &__imgs {
            order: 1;
            width: 100%;
            max-width: 480px;
            margin: 0 auto;
        }
        &__text {
            order: 2;
            width: 100%;
            margin: 30px 0 0;
        }
        &__subtitle {
            text-align: center;
        }
        &__title {
            max-width: 270px;
            font-size: 32px;
            margin: 13px auto 0;
            text-align: center;
        }
        &__desc {
            margin: 15px auto 0;
            text-align: center;
        }
        &__form {
            margin: 30px auto 0;
            max-width: 400px;
        }
        &__kv {
            display: none;
        }
    }

    .banner-imgs {
        margin: 0 -5px;
        height: 225px;
        &__line {
            width: 374px;
            height: 234px;
            left: 50%;
            transform: translateX(-50%);
        }
        &__bl {
            padding: 0 5px;
            &--1 {
                height: 77px;
            }
            &--2 {
                left: unset;
                top: 0;
                bottom: unset;
                height: 77px;
            }
            &--4 {
                top: unset;
                bottom: 0;
                left: 0;
                height: 138px;
                width: 46%;
            }
            &--3 {
                left: unset;
                right: 0;
                height: 138px;
                width: 54%;
            }
        }
        &__picture {
            border-radius: 24px;
        }
        &__address {
            border-radius: 24px;
            &-svg {
                width: 53px;
                height: 76px;
            }
            &-title {
                margin: 15px 0 0;
            }
        }
    }
}