.footer {
    background: $text;
    padding: 42px 0;
    color: #fff;
    position: relative;
    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__logo {
        display: block;
        width: 134px;
        flex-shrink: 0;
    }
    &__menu {
        font: 400 12px / 1 $font;
        text-transform: uppercase;
        &-list {
            display: flex;
            margin: 0 -13px;
        }
        &-li {
            padding: 0 13px;
        }
        &-a {
            &:hover,
            &.active {
                color: $green;
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .footer {
        padding: 36px 0 50px;
        &__wrapper {
            flex-wrap: wrap;
        }
        &__social {
            transform: scale(1.3);
            transform-origin: 100% 50%;
        }
        &__menu {
            order: 3;
            width: 100%;
            margin: 50px 0 0;
            &-list {
                justify-content: space-between;
            }
        }
    }
}


@media screen and (max-width: 767px) {
    .footer {
        padding: 80px 0 60px;
        &__wrapper {
            justify-content: center;
        }
        &__menu {
            order: 2;
            font-size: 14px;
            margin: 40px 0;
            &-list {
                flex-wrap: wrap;
            }
            &-li {
                width: 100%;
            }
            &-a {
                padding: 12px 0;
                display: block;
                text-align: center;
            }
        }
        &__social {
            order: 3;
            transform-origin: 50% 50%;
        }
    }
}