.trial {
    margin: 60px 0 0;
    &__wrapper {
        display: flex;
        align-items: flex-end;
    }
    &__img {
        flex-grow: 1;
        height: 384px;
        background: $text;
        color: #fff;
        position: relative;
        border-radius: 20px;
        overflow: hidden;
    }
    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            background: linear-gradient(284.23deg, rgba(30, 30, 30, 0.5) 15%, rgba(0, 0, 0, 0) 30%)
        }
        img {
            @extend .fit-cover;
        }
    }
    &__bl {
        position: relative;
        z-index: 1;
        position: absolute;

        &--1 {
            max-width: 252px;
            top: 30px;
            left: 36px;
        }
        &--2 {
            margin-left: auto;
            max-width: 142px;
            right: 36px;
            bottom: 30px;
        }
    }
    &__title {
        font: 900 36px / 1.2 $font;
    }
    &__subtitle {
        font: 700 32px / 1.2 $font;
        span {
            font-style: italic;
            color: $green;
        }
    }
    &__form {
        flex-shrink: 0;
        width: 350px;
        margin: 0 0 0 40px;
        .desc-sec {
            max-width: 311px;
            margin: 15px 0 20px;
        }
    }
    &__form-tablet {
        display: none;
    }
}

@media screen and (max-width: 1200px) {
    .trial {
        &__form {
            margin: 0 0 0 30px;
            width: 300px;
        }
        &__subtitle {
            font-size: 28px;
        }
    }
}

@media screen and (max-width: 992px) {
    .trial {
        margin: 100px 0 0;
        &__wrapper {
            flex-wrap: wrap;
        }
        .desc-sec {
            max-width: 495px;
            margin: 20px 0 0;
        }
        &__img {
            order: 2;
            width: 100%;
            margin: 32px -36px 0;
            border-radius: 0;
            padding: 44px 36px;
            overflow: visible;
        }
        &__bg {
            overflow: hidden;
            &::before {
                background: linear-gradient(to right, rgba(#000, .6), rgba(#000, 0) 70%);
            }
            img {
                width: 1100px;
                margin: -115px 0 0;
                max-width: unset;
                object-fit: unset;
                object-position: unset;
                height: auto;
                min-height: 100%;
            }
        }
        &__bl {
            position: relative;
            margin: 0 0 35px;
            &--1 {
                max-width: 100%;
                top: 0;
                left: 0;
            }
            &--2 {
                max-width: 100%;
                right: 0;
                bottom: 0;
            }
        }
        &__subtitle {
            font-size: 20px;
            margin: 2px 0 0;
        }
        &__form {
            order: 2;
            width: 100%;
            margin: 2rem auto auto;
            .desc-sec {
                max-width: 100%;
                margin: 2rem auto;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .trial {
        margin: 90px 0 0;
        &__title {
            text-align: center;
        }
        &__subtitle {
            text-align: center;
            font-size: 16px;
        }
        &__bg {
            &::before {
                content: '';
                background: rgba(#000, .5);
            }
            img {
                width: auto;
                min-width: 100%;
                min-height: 100%;
                margin: 0;
                float: right;
            }
        }
        &__bl-container { /* добавлено */
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            height: 100%;
            padding: 30px 0;
        }

        &__bl {
            margin: 0 0 25px;
        }
        &__form-tablet {
            margin: 40px auto 0;
        }
        &__form {
            margin: 4rem auto auto;
        }
    }
}


@media screen and (max-width: 375px) {
    .trial {
        &__bg {
            img {
                margin-right: -50px;
            }
        }
    }
}