.maps {

  .maps__items {
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;

    @media (min-width: $mobile) {
      justify-content: space-between;
      flex-direction: row;
    }
  }

  .maps__item {
    width: 100%;
    position: relative;
    border-radius: 30px;
    margin-bottom: 32px;
    overflow: hidden;

    @media (min-width: $mobile) {
      margin-bottom: 0;
    }
  }
  & iframe {
    width: 100%;
    position: relative;
    z-index: 1;
  }

  .maps__description {
    border-radius: 21px;
    border: 1px solid #020202;
    background-color: rgba(255, 255, 255, 0.85);
    max-width: 324px;
    width: 100%;
    position: absolute;
    bottom: 22px;
    left: 0;
    z-index: 2;
    padding: 16px 24px;

    color: #020202;
    font-size: 14px;
    line-height: 150%;

    @media (min-width: 368px) {
      left: 22px;
    }

    @media (min-width: $mobile) {
      font-size: 16px;
      height: 200px;
    }

    p{
      font-weight: 700;
    }
  }
  .maps__address {
    margin-bottom: 10px;
  }
  .maps__schedule {
    margin-bottom: 10px;
  }
  .maps__number {
    font-weight: 700;
    transition: color .3s ease-in-out;

    &:hover {
      color: $blue;
    }
  }
}
