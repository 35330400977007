$trans: ease;

$bg: #FAFDFF;
$green: #BDFF02;
$greenH: #7CFF00;
$blue: #3502FF;
$blueH: #2700c3;
$text: #020202;
$text2: #090909;
$placeholder: #95A0A6;
$pink: #FF00B8;

$font: 'Montserrat', sans-serif;

$minWidth: 320px;
$maxWidth: 1440px;
$maxWidthContainer: 1140px;
$pc: $maxWidthContainer + 12;
$tablet: 991.98px;
$mobile: 767.98px;
$mobileSmall: 479.98px;