.contacts {
    margin: 120px 0 0;
    &__map {
        height: 544px;
        background: rgba(#000, .05);
        padding: 84px 0 0;
        margin: 45px 0 0;
        position: relative;
    }
    &__iframe {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        &-inner {
            width: 100%;
            height: 100%;
        }
    }
    .container {
        pointer-events: none;
    }
    &__bl {
        background: #fff;
        border: 1px solid $text;
        padding: 40px 40px 30px;
        border-radius: 20px;
        max-width: 426px;
        pointer-events: all;
        position: relative;
    }
    &__item {
        margin: 0 0 15px;
        &-title {
            font-weight: 700;
        }
    }
    &__phones {
        margin: 34px 0 0;
        font-weight: 700;
        a {
            &:hover {
                color: $blue;
            }
        }
    }
    &__social {
        display: flex;
        justify-content: flex-end;
        margin: 10px 0 0;
    }
}

@media screen and (max-width: 992px) {
    .contacts {
        margin: 100px 0 0;
        &__map {
            margin: 34px 0 0;
            height: auto;
            padding: 0;
        }
        &__iframe {
            position: relative;
            height: 334px;
        }
        &__bl {
            max-width: unset;
            margin: 0 -36px;
            border: 0;
            border-top: 1px solid $text;
            border-radius: 0;
            text-align: center;
        }
        &__item {
            margin: 0 0 20px;
        }
        &__phones {
            margin: 28px 0 0;
            li {
                margin: 0 0 15px;
            }
        }
        &__social {
            justify-content: center;
            margin: 20px 0 0;
        }
    }
}


@media screen and (max-width: 767px) {
    .contacts {
        margin: 90px 0 0;
        &__map {
            margin: 20px 0 0;
        }
    }
}