.reviews {
    margin: 120px 0 0;
    position: relative;
    &__line1 {
        width: 2296px;
        height: 910px;
        top: 130px;
        transform: translateX(-50%);
        left: 50%;
    }
    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__arrows {
        display: flex;
    }
    &__slider {
        overflow: hidden;
        margin: 45px 0 0;
        &-wrapper {
            display: flex;
        }
        &-slide {
            flex-shrink: 0;
        }
    }
    &__video {
        margin: 34px 0 0;
        height: 480px;
        position: relative;
        cursor: pointer;
        overflow: hidden;
        border-radius: 20px;
        &:hover {
            svg {
                transform: scale(1.3);
            }
            img {
                transform: scale(1.1);
            }
        }
        img {
            @extend .fit-cover;
            transition: .6s $trans;
        }
    }
    &__play {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        fill: #fff;
        z-index: 1;
        svg {
            width: 52px;
            height: 52px;
            transition: .3s $trans;
        }
    }
}

.arrow {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    fill: #C2D1D9;
    cursor: pointer;
    user-select: none;
    transition: .3s $trans;
    flex-shrink: 0;
    &:hover {
        fill: $text;
    }
    svg {
        width: 16px;
        height: 16px;
    }
    &--next {
        svg {
            transform: rotate(180deg);
        }
    }
}

.reviews-item {
    padding: 36px 36px 20px;
    background: #F2F5F7;
    cursor: pointer;
    border-radius: 20px;
    display: block;
    height: 100%;
    &:hover {
        img {
            transform: scale(1.1);
        }
    }
    &__img {
        height: 218px;
        overflow: hidden;
        border-radius: 20px;
        img {
            @extend .fit-cover;
            transition: .6s $trans;
        }
    }
    &__title {
        display: flex;
        align-items: center;
        fill: #424B5A;
        color: #A1AEB7;
        margin: 15px 0 0;
        svg {
            width: 20px;
            height: 15px;
            margin: 0 16px 0 0;
        }
    }
}


@media screen and (max-width: 992px) {
    .reviews {
        &__line1 {
            display: none;
        }
    }

    .reviews-item {
        padding: 30px 30px 20px;
    }
}

@media screen and (max-width: 767px) {
    .reviews {
        margin: 80px 0 0;
        &__top {
            flex-wrap: wrap;
        }
        &__arrows {
            margin: 30px 0 0;
            width: 100%;
            justify-content: center;
        }
        &__slider {
            margin: 20px 0 0;
        }
        &__video {
            border-radius: 0;
            height: 230px;
            margin: 24px -26px 0;
        }
    }

    .reviews-item {
        padding: 26px 26px 20px;
        &__img {
            border-radius: 15px;
        }
    }
}