.specials {
  position: relative;
  margin-bottom: 60px;

  &__line3 {
    width: 3492px;
    height: 800px;
    left: 10%;
    top: -30%;
    transform: translateX(-50%);
    bottom: -80px;
    margin-left: 500px;
    stroke-width: 2;
  }

  &__title {
    font: 900 3rem / 1.2 $font;
    text-align: center;
    margin: 0 0 20px;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  }

  &__subtitle {
    font: 400 1.5rem / 1.2 $font;
    text-align: center;
    margin: 0 0 60px;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  }

  &__subtitle span {
    font-style: italic;
    color: #BDFF02;
  }

  &__conditions span {
    font-weight: 700;
    color: #BDFF02;
  }

  &__desc-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 0 48px;
    flex-wrap: wrap;
  }

  &__description {
    font: 400 1.5rem / 1.2 $font;
    margin-bottom: 2rem;
  }

  &__desc-left-col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 64%;
    background: rgba(0, 0, 0, 0.85); /* черный фон с 50% прозрачностью */
    padding: 24px 36px;
    border-radius: 20px;
  }

  &__desc-right-col {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: flex-start;
    height: 281px;
    padding: 24px 0;
  }

  .trial__form {
    margin: 0 auto;
  }

  &__terms {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);

    p {
      margin: 0 0 1rem;
    }
  }

  &__terms-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__banner {
    position: relative;
    margin: 120px 0 24px;
    color: #fff;
    background: #1E1E1E;
    border-radius: 20px;
    padding: 64px 24px 4px;
    z-index: 1;

    &__sale-price {
      position: absolute;
      top: 0;
      right: 0;
      background: rgba(255, 0, 184, 0.94);
      height: 120px;
      width: 120px;
      transform: translate(50%, -50%) rotate(-9.2deg);
      z-index: 5;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease-in-out;
    }

    &__sale-price:hover {
      transform: translate(50%, -50%) rotate(9.2deg) scale(1.1);
    }

    &__sale-price p {
        font: 400 2rem / 1 $font;
        color: #fff;
        text-align: center;
    }

    &__background-video {
      position: absolute;
      border-radius: 20px;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
    }
  }

  &__banner::after {
    content: "";
    border-radius: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1); /* черный фон с 50% прозрачностью */
    z-index: 1;
  }

  &__inner {
    position: relative;
    z-index: 2;
  }

  &__prices-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    gap: 24px;
    margin-bottom: 24px;
  }

  &__prices-col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    text-align: center;
    background: #0C0C0C;
    color: #fff;
    box-shadow: 0 4px 18px rgba(66, 75, 90, 0.15);
    border-radius: 20px;
    padding: 34px 30px;
  }

  &__prices-title {
    font: 400 1.5rem / 1.2 $font;
    margin-bottom: 24px;
  }

  &__prices-list {
    width: 100%;
  }

  &__prices-item {
    display: flex;
    justify-content: space-between;
  }

  &__prices-item-price {
    color: $green;
  }

  &__price-old {
    display: inline-block;
    width: 68px;
    text-decoration: line-through;
    text-align: right;
    font-style: italic;
    color: #fff;
  }

  &__price-new {
    display: inline-block;
    width: 4rem;
    text-align: right;
  }

}

@media screen and (max-width: 992px) {
    .specials {

      &__desc-wrapper {
        margin: 0 0 24px;
      }

      &__desc-left-col {
        max-width: 100%;
        padding: 24px 16px;
        margin-bottom: 24px;
      }

      &__description {
        text-align: center;
      }

      &__banner {
        &__sale-price {
          display: none;
        }
      }

      &__desc-right-col {
        flex-direction: column;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 0;
        height: auto;
      }

      .trial__form {
        margin: 0 auto;
      }

      &__terms {
        align-items: center;
        text-align: center;
        margin-bottom: 24px;
      }

      &__terms-list {
        align-items: center;
        text-align: center;
      }
    }
}

@media screen and (max-width: 767px) {
  .specials {
    &__prices-col {
      max-width: 100%;
      width: 100%;
    }

    &__prices-row {
      flex-direction: column;
      gap: 24px;
    }

    &__prices-item-title {
      .mobile-hide {
        display: none;
      }
    }
  }
}