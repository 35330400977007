.toast-error {
  background-color: #FF00B8;
}

.toast-success {
  background-color: #3502FF;
}

.phone__item > label {
  display: block;
  color: #FF00B8;
}