.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    &.fixed {
        background: $bg;
    }
    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 76px;
    }
    &__logo {
        display: block;
        width: 134px;
        flex-shrink: 0;
    }
    &__right {
        display: flex;
        align-items: center;
    }
    &__menu {
        font-size: 12px;
        text-transform: uppercase;
        &-inner {
            display: flex;
            align-items: center;
        }
    }
    &__nav {
        &-list {
            display: flex;
            margin: 0 -13px;
        }
        &-li {
            padding: 0 13px;
        }
        &-a {
            display: block;
            padding: 10px 0;
            position: relative;
            &:hover,
            &.active {
                color: $blue;
                &::after {
                    width: 100%;
                }
            }
            &::after {
                content: '';
                height: 2px;
                width: 0%;
                position: absolute;
                bottom: 0;
                left: 0;
                background: $blue;
                transition: .3s $trans;
            }
        }
    }
    &__info {
        display: flex;
    }
    &__address {
        display: flex;
        align-items: center;
        fill: $blue;
        margin: 0 0 0 30px;
        &:hover {
            color: $blue;
        }
        svg {
            width: 11px;
            height: 15px;
            margin: 0 10px 0 0;
        }
    }
    &__phone {
        display: block;
        color: $blue;
        margin: 0 0 0  30px;
        &:hover {
            color: $text;
        }
        &__mobile {
            display: none;

            @media (max-width: 992px) {
                font-size: 12px;
                display: inline-block;
                color: $blue;
            }

            @media (max-width: 364px) {
                display: none;
            }
        }
    }
    &__social {
        margin: 0 0 0 25px;
    }
    &__toggle {
        width: 32px;
        height: 22px;
        position: relative;
        display: none;
        margin-left: 20px;
        span {
            height: 2px;
            width: 100%;
            left: 0;
            position: absolute;
            background: #000;
            transition: .3s $trans;
            &:nth-child(1) {
                top: 0;
                transform-origin: 0 0;
            }
            &:nth-child(2) {
                top: 50%;
                margin: -1px 0 0;
            }
            &:nth-child(3) {
                bottom: 0;
                transform-origin: 0 100%;
            }
        }
        &.active-target {
            span {
                &:nth-child(1) {
                    transform: translate(5px, -1px) rotate(45deg);
                }
                &:nth-child(2) {
                    opacity: 0;
                }
                &:nth-child(3) {
                    transform: translate(5px, 1px) rotate(-45deg);
                }
            }
        }
    }

    .dropdown {
        position: relative;
    }

    .dropdown-menu {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        background-color: #fff;
        padding: 8px 12px;
        min-width: 180px;
        z-index: 1000;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }

    .dropdown-menu a {
        color: #333;
        padding: 8px 16px;
        text-decoration: none;
        display: block;
        transition: background-color 0.3s;
    }

    .dropdown-menu a:hover {
        background-color: #f1f1f1;
    }

    .dropdown:hover .dropdown-menu {
        display: block;
    }

    .dropdown-toggle {
        position: relative;
        display: inline-block;
    }

}


@media screen and (max-width: 1200px) {
    .header {
        &__info {
            display: block;
            margin: 0 0 0 30px;
        }
        &__address {
            margin: 0;
        }
        &__phone {
            margin: 0 0 0 18px;
        }
    }
}

@media screen and (max-width: 992px) {
    .header {
        &__toggle {
            display: block;
        }
        &__menu {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: $bg;
            z-index: -1;
            overflow-y: auto;
            padding: 100px 30px 36px;
            visibility: hidden;
            opacity: 0;
            transform: scale(.9);
            transition: .3s $trans;
            &.active-target {
                visibility: visible;
                opacity: 1;
                transform: none;
            }
            &-inner {
                max-width: 500px;
                margin: 0 auto;
                flex-wrap: wrap;
                justify-content: space-between;
            }
        }
        &__nav {
            width: 100%;
            &-list {
                flex-wrap: wrap;
                margin: 0 -34px 30px;
            }
            &-li {
                width: 100%;
            }
            &-a {
                padding: 10px 20px;
                border-radius: 5px;
                font-size: 16px;
                &:hover,
                &.active {
                    background: rgba($blue, .05);
                }
                &::after {
                    content: none;
                }
            }
        }
        &__info {
            display: flex;
            margin: 0;
        }
        &__address {
            margin: 0;
        }

        .dropdown-menu {
            display: block !important;
            position: static;
            background-color: transparent;
            box-shadow: none;
            padding-left: 1rem;
        }
        .dropdown-menu a {
            padding: 10px 20px;
        }
        .caret-icon {
            display: none;
        }
    }
}


@media screen and (max-width: 767px) {
    .header {
        &__menu {
            padding: 100px 26px 30px;
        }
        &__info {
            display: block;
            margin: 0 !important;
        }
        &__social {
            align-self: flex-start;
        }
    }
}


